import CryptoJS from 'crypto-js/crypto-js'

/*
   * La CLAVE IV predeterminada Si la CLAVE y IV no se pasan durante el cifrado y descifrado, se utilizará la definida aquí
 * 
   * Las claves de front-end y back-end deben ser consistentes con el vector inicial durante la interacción de front-end
 */

const KEY = CryptoJS.enc.Utf8.parse("APIJATON.9401824");// La longitud de la clave debe ser de 16 bits
const IV = CryptoJS.enc.Utf8.parse("9401824");           // La longitud inicial del vector es arbitraria

/*
   * Cifrado AES: la clave de cadena iv devuelve base64
 */
export function Encrypt(str, keyStr, ivStr) {
    let key = KEY
    let iv = IV

    if (keyStr && ivStr) {
        key = CryptoJS.enc.Utf8.parse(keyStr);
        iv = CryptoJS.enc.Utf8.parse(ivStr);
    }

    let srcs = CryptoJS.enc.Utf8.parse(str);
    var encrypt = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,            // Puede elegir el modo de cifrado AES aquí
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encrypt.ciphertext);
}

/*
   * Descifrado AES: la clave de cadena iv devuelve base64
 */
export function Decrypt(str, keyStr, ivStr) {
    let key = KEY
    let iv = IV

    if (keyStr && ivStr) {
        key = CryptoJS.enc.Utf8.parse(keyStr);
        iv = CryptoJS.enc.Utf8.parse(ivStr);
    }

    let base64 = CryptoJS.enc.Base64.parse(str);
    let src = CryptoJS.enc.Base64.stringify(base64);

    var decrypt = CryptoJS.AES.decrypt(src, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,            // Puede elegir el modo de descifrado AES aquí
        padding: CryptoJS.pad.Pkcs7
    });

    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}